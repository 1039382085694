/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../setup";
import {useHistory, Link} from 'react-router-dom'
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  className: string
}

const TableApproveCustomer: React.FC<Props> = ({className}) => {
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  const [customers, setCustomers] = useState([])
  const history = useHistory()

  useEffect(() => {
    axios.post(`${BACKEND_URL}/customer/read-by-sale`, {
      access_token: accessToken
    }).then(data => data.data)
      .then(resData => {
        if (resData.error_code === 1) {
          setCustomers(resData.data.customers)
        }
      })
    return () => {}
  }, [])

  const approveCustomer = (customerId: any) => {
    if (window.confirm('Xác nhận duyệt người dùng này?')) {
      axios.post(`${BACKEND_URL}/customer/approve`, {
        access_token: accessToken,
        user_id: customerId,
      }).then(data => data.data)
        .then(resData => {
          if (resData.error_code === 1) {
            toast("Duyệt người dùng mới thành công", {
              onClose: () => {
                window.location.reload()
              },
              autoClose: 3000,
            })
          } else {
            toast("Duyệt người dùng mới thất bại, vui lòng liên hệ quản trị viên", {
              onClose: () => {
                history.push('/manage-customer')
              },
              autoClose: 3000,
            })
          }
        })
      return () => {}
    }
  }

  const deleteCustomer = (customerId: any) => {
    if (window.confirm('Xác nhận xóa người dùng này?')) {
      axios.post(`${BACKEND_URL}/customer/delete`, {
        access_token: accessToken,
        user_id: customerId,
      }).then(data => data.data)
        .then(resData => {
          if (resData.error_code === 1) {
            toast("Xóa người dùng mới thành công", {
              onClose: () => {
                window.location.reload()
              },
              autoClose: 3000,
            })
          } else {
            toast("Xóa người dùng mới thất bại, vui lòng liên hệ quản trị viên", {
              onClose: () => {
                history.push('/manage-customer')
              },
              autoClose: 3000,
            })
          }
        })
      return () => {}
    }
  }

  return (
    <div className={`card ${className}`}>
      <ToastContainer />
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>Danh sách người dùng chờ duyệt</span>
        </h3>
        <div
          className='card-toolbar d-none'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Thêm người dùng mới'
        >
          <a
            href='#'
            className='btn btn-sm btn-light-primary'
            // data-bs-toggle='modal'
            // data-bs-target='#kt_modal_invite_friends'
            onClick={(e) => {
              e.preventDefault()
              history.push('/create-customer')
            }}
          >
            <KTSVG path='media/icons/duotone/Communication/Add-user.svg' className='svg-icon-3' />
            Thêm người dùng mới
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
            <tr className='fw-bolder text-muted'>
              <th className='min-w-150px'>Tên người dùng</th>
              <th className='min-w-140px'>Số điện thoại</th>
              <th className='min-w-120px'>Duyệt người dùng</th>
              <th className='min-w-120px'>Xóa người dùng</th>
            </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}
            <tbody>
            {customers.map((customer: any) => {
              if (!customer.is_active) {
                return (
                  <tr key={customer.id}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='d-flex justify-content-start flex-column'>
                          <a href='#' className='text-dark fw-bolder text-hover-primary fs-6'>
                            {customer.name ?? ''}
                          </a>
                        </div>
                      </div>
                    </td>
                    <td>
                      <a href='#' className='text-dark fw-bolder text-hover-primary d-block fs-6'>
                        {customer.phone ?? ''}
                      </a>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <button
                          type='button'
                          className='btn btn-sm btn-light-primary'
                          // data-bs-toggle='modal'
                          // data-bs-target='#kt_modal_invite_friends'
                          onClick={(e) => {
                            approveCustomer(customer.id)
                          }}
                        >
                          <KTSVG path='media/icons/duotone/Communication/Add-user.svg' className='svg-icon-3' />
                        </button>
                      </div>
                    </td>
                    <td className='text-end'>
                      <div className='d-flex flex-column w-100 me-2'>
                        <button
                          type='button'
                          className='btn btn-sm btn-light-primary'
                          // data-bs-toggle='modal'
                          // data-bs-target='#kt_modal_invite_friends'
                          onClick={(e) => {
                            deleteCustomer(customer.id)
                          }}
                        >
                          <KTSVG path='media/icons/duotone/Communication/Delete-user.svg' className='svg-icon-3' />
                        </button>
                      </div>
                    </td>
                  </tr>
                )
              }
            })}
            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export {TableApproveCustomer}
