/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {shallowEqual, useSelector} from "react-redux";
import {RootState} from "../../../../setup";

export function AsideMenuMain() {
  const intl = useIntl()
  const authInfo: any = useSelector<RootState>(({auth}) => auth, shallowEqual)
  let authRoleID = undefined
  if (authInfo) {
    authRoleID = authInfo.user.user.auth_role_id
  }

  return (
    <>
      {authRoleID === 1&&
      <>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/manage-shareholder'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.MANAGE_SHAREHOLDER'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/admin-approve-quantity'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={'Duyệt số lượng tài khoản'}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/all-agency'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.All_AGENCY'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/all-sale'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.All_SALE'})}
          fontIcon='bi-app-indicator'
        />
      </>
      }

      {authRoleID === 6&&
      <>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/manage-shareholder-by-director'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.MANAGE_SHAREHOLDER'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/shareholder-approve-quantity'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.APPROVE_SHAREHOLDER_QUANTITY'})}
          fontIcon='bi-app-indicator'
        />
      </>
      }

      {authRoleID === 2&&
      <>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/manage-agency'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.MANAGE_AGENCY'})}
          fontIcon='bi-app-indicator'
        />
      </>
      }

      {authRoleID === 3&&
      <>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/manage-customer'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.MANAGE_CUSTOMER'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/manage-sale'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.MANAGE_SALE'})}
          fontIcon='bi-app-indicator'
        />
      </>
      }

      {authRoleID === 5 &&
      <>
        <AsideMenuItem
          to='/dashboard'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/manage-customer'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.MANAGE_CUSTOMER'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/approve-customer'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.APPROVE_CUSTOMER'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
            to='/sale-create-sale'
            icon='/media/icons/duotone/Design/PenAndRuller.svg'
            title={intl.formatMessage({id: 'MENU.SALE_CREATE_SALE'})}
            fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/partner-list'
          icon='/media/icons/duotone/Design/PenAndRuller.svg'
          title={intl.formatMessage({id: 'MENU.PARTNER_SALE'})}
          fontIcon='bi-app-indicator'
        />
      </>
      }

    </>
  )
}
