import React, {Suspense, lazy} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {ChangePassword} from "../pages/dashboard/ChangePassword";
import {ChangeBankInfo} from "../pages/dashboard/ChangeBankInfo";

import {ManageShareholder} from '../pages/dashboard/ManageShareholder'
import {CreateShareholder} from '../pages/dashboard/CreateShareholder'
import {ViewShareholder} from '../pages/dashboard/ViewShareholder'
import {EditShareholder} from '../pages/dashboard/EditShareholder'

import {ManageShareholderByDirector} from '../pages/dashboard/ManageShareholderByDirector'
import {EditShareholderByDirector} from '../pages/dashboard/EditShareholderByDirector'
import {ViewShareholderByDirector} from '../pages/dashboard/ViewShareholderByDirector'

import {ManageShareholderApproveQuantity} from '../pages/dashboard/ManageShareholderApproveQuantity'

import {AdminApproveQuantity} from '../pages/dashboard/AdminApproveQuantity'

import {ManageAgency} from "../pages/dashboard/ManageAgency";
import {CreateAgency} from '../pages/dashboard/CreateAgency'
import {ViewAgency} from '../pages/dashboard/ViewAgency'
import {EditAgency} from '../pages/dashboard/EditAgency'

import {TableAgencyByAdmin} from '../pages/dashboard/TableAgencyByAdmin'

import {ManageSale} from "../pages/dashboard/ManageSale";
import {CreateSale} from '../pages/dashboard/CreateSale'
import {ViewSale} from '../pages/dashboard/ViewSale'
import {EditSale} from '../pages/dashboard/EditSale'

import {TableSaleBySale} from '../pages/dashboard/TableSaleBySale'
import {TableSaleByAdmin} from '../pages/dashboard/TableSaleByAdmin'

import {SaleCreateSale} from '../pages/dashboard/SaleCreateSale'

import {ManageCustomer} from "../pages/dashboard/ManageCustomer";
import {ApproveCustomer} from "../pages/dashboard/ApproveCustomer";

import {ApproveCustomerPaid} from "../pages/dashboard/ApproveCustomerPaid";

import {CreateCustomer} from "../pages/dashboard/CreateCustomer";

import {MenuTestPage} from '../pages/MenuTestPage'

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={DashboardWrapper} />

        <Route path='/change-password' component={ChangePassword} />
        <Route path='/change-bank-info' component={ChangeBankInfo} />

        <Route path='/manage-shareholder' component={ManageShareholder} />
        <Route path='/create-shareholder' component={CreateShareholder} />
        <Route path='/view-shareholder/:id' component={ViewShareholder} />
        <Route path='/edit-shareholder/:id' component={EditShareholder} />

        <Route path='/manage-shareholder-by-director' component={ManageShareholderByDirector} />
        <Route path='/edit-shareholder-by-director/:id' component={EditShareholderByDirector} />
        <Route path='/view-shareholder-by-director/:id' component={ViewShareholderByDirector} />

        <Route path='/shareholder-approve-quantity' component={ManageShareholderApproveQuantity} />

        <Route path='/admin-approve-quantity' component={AdminApproveQuantity} />

        <Route path='/manage-agency' component={ManageAgency} />
        <Route path='/create-agency' component={CreateAgency} />
        <Route path='/view-agency/:id' component={ViewAgency} />
        <Route path='/edit-agency/:id' component={EditAgency} />

        <Route path='/all-agency' component={TableAgencyByAdmin} />

        <Route path='/manage-sale' component={ManageSale} />
        <Route path='/create-sale' component={CreateSale} />
        <Route path='/view-sale/:id' component={ViewSale} />
        <Route path='/edit-sale/:id' component={EditSale} />

        <Route path='/partner-list' component={TableSaleBySale} />

        <Route path='/all-sale' component={TableSaleByAdmin} />

        <Route path='/sale-create-sale' component={SaleCreateSale} />

        <Route path='/manage-customer' component={ManageCustomer} />
        <Route path='/approve-customer' component={ApproveCustomer} />

        <Route path='/approve-customer-paid' component={ApproveCustomerPaid} />

        <Route path='/create-customer' component={CreateCustomer} />

        <Route path='/builder' component={BuilderPageWrapper} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route path='/crafted/pages/wizards' component={WizardsPage} />
        <Route path='/crafted/widgets' component={WidgetsPage} />
        <Route path='/crafted/account' component={AccountPage} />
        <Route path='/apps/chat' component={ChatPage} />
        <Route path='/menu-test' component={MenuTestPage} />
        <Redirect from='/auth' to='/dashboard' />
        <Redirect exact from='/' to='/dashboard' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
