/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {toAbsoluteUrl} from "../../_metronic/helpers";
import {Link} from "react-router-dom";
import {shallowEqual, useSelector} from "react-redux";

const MenuTwo = ({active}) => {
  const WIDTH = window.innerWidth > 400 ? '400px' : window.innerWidth
  const currentUser = useSelector(({auth}) => auth.user, shallowEqual)

  const style = {
    menuContainer: {
      position: 'fixed',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      width: WIDTH,
      height: '70px',
      margin: 'auto'
    },
    menuElement: {
      width: currentUser.user.level === 3 ? '33.333%' : '50%',
      height: '100%',
      backgroundColor: '#fff',
      display: 'inline-block',
      textAlign: 'center',
      paddingTop: '10px'
    }
  }

  return (
    <>
      {currentUser.user.level < 3 && <>
        <div style={{height: '70px'}}>&nbsp;</div>
        <div style={style.menuContainer}>
          <div style={style.menuElement}>
            <Link to='/customer/report-image'>
              <div style={{padding: '0px 13px'}}>
                <div style={{border: active === 'report' ? '1px solid #70cfff' : '1px solid #fff', borderRadius: '10px', backgroundColor: active === 'report' ? '#ade0fd' : '#fff'}}>
                  <img
                    alt='Logo'
                    className='h-50px'
                    src={toAbsoluteUrl('/media/logos/1-bao-cao.png')}
                  />
                  <img
                    style={{ marginTop: '-5px', marginLeft: '15px' }}
                    alt='Logo'
                    className='h-40px'
                    src={toAbsoluteUrl(active === 'report' ? '/media/logos/report_2.png' : '/media/logos/report_1.png')}
                  />
                </div>
              </div>
            </Link>
          </div>
          <div style={style.menuElement}>
            <Link to='/customer/list-study'>
              <div style={{padding: '0px 13px'}}>
                <div style={{border: active === 'study' ? '1px solid #70cfff' : '1px solid #fff', borderRadius: '10px', backgroundColor: active === 'study' ? '#ade0fd' : '#fff'}}>
                  <img
                    alt='Logo'
                    className='h-50px'
                    src={toAbsoluteUrl('/media/logos/2-khoa-hoc.png')}
                  />
                  <img
                    style={{ marginTop: '-5px', marginLeft: '15px' }}
                    alt='Logo'
                    className='h-40px'
                    src={toAbsoluteUrl(active === 'report' ? '/media/logos/study_2.png' : '/media/logos/study_1.png')}
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </>}
      {currentUser.user.level === 3 && <>
        <div style={{height: '70px'}}>&nbsp;</div>
        <div style={style.menuContainer}>
          <div style={style.menuElement}>
            <Link to='/customer/report-image'>
              <div style={{padding: '0px 5px'}}>
                <div style={{border: active === 'report' ? '1px solid #70cfff' : '1px solid #fff', borderRadius: '10px', backgroundColor: active === 'report' ? '#ade0fd' : '#fff'}}>
                  <img
                    alt='Logo'
                    className='h-50px'
                    src={toAbsoluteUrl('/media/logos/1-bao-cao.png')}
                  />
                  <img
                    style={{ marginTop: '-5px', marginLeft: '5px' }}
                    alt='Logo'
                    className='h-40px'
                    src={toAbsoluteUrl(active === 'report' ? '/media/logos/report_2.png' : '/media/logos/report_1.png')}
                  />
                </div>
              </div>
            </Link>
          </div>
          <div style={style.menuElement}>
            <Link to='/customer/list-study'>
              <div style={{padding: '0px 5px'}}>
                <div style={{border: active === 'study' ? '1px solid #70cfff' : '1px solid #fff', borderRadius: '10px', backgroundColor: active === 'study' ? '#ade0fd' : '#fff'}}>
                  <img
                    alt='Logo'
                    className='h-50px'
                    src={toAbsoluteUrl('/media/logos/2-khoa-hoc.png')}
                  />
                  <img
                    style={{ marginTop: '-5px', marginLeft: '5px' }}
                    alt='Logo'
                    className='h-40px'
                    src={toAbsoluteUrl(active === 'study' ? '/media/logos/study_1.png' : '/media/logos/study_2.png')}
                  />
                </div>
              </div>
            </Link>
          </div>
          <div style={style.menuElement}>
            <Link to='/customer/map'>
              <div style={{padding: '0px 5px'}}>
                <div style={{border: active === 'map' ? '1px solid #70cfff' : '1px solid #fff', borderRadius: '10px', backgroundColor: active === 'map' ? '#ade0fd' : '#fff'}}>
                  <img
                    alt='Logo'
                    className='h-50px'
                    src={toAbsoluteUrl('/media/logos/giao-tiep.png')}
                  />
                  <img
                    style={{ marginTop: '-5px', marginLeft: '5px' }}
                    alt='Logo'
                    className='h-40px'
                    src={toAbsoluteUrl(active === 'map' ? '/media/logos/giao-tiep-2.png' : '/media/logos/giao-tiep-1.png')}
                  />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </>}
    </>
  )
}

export {MenuTwo}
